/*! CSS Used from: https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css */
*,
::after,
::before {
    box-sizing: border-box;
}

nav {
    display: block;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

a {
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

button {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button {
    overflow: visible;
}

button {
    text-transform: none;
}

[type=button],
button {
    -webkit-appearance: button;
}

[type=button]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width:576px) {
    .container {
        max-width: 540px;
    }

}

@media (min-width:768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px;
    }
}

@media (min-width:576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px;
    }
}

.collapse:not(.show) {
    display: none;
}

.nav-link {
    display: block;
    padding: 1.75rem 1rem;
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.9);
}

.nav-link:focus,
.nav-link:hover {
    text-decoration: none;
}

.navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: .5rem 1rem;
}

.navbar .container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none;
}

.navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
    background-color:rgba(46,49,49,0.9);
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width:991.98px) {
    .navbar-expand-lg>.container {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
        border-bottom:none;
    }   

    .navbar-expand-lg>.container {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }

    .navbar-expand-lg .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        background-color: transparent;
    }

    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

.navbar-dark .navbar-brand {
    color: #fff;
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, .75);
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, .5);
    border-color: rgba(255, 255, 255, .1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.ml-auto {
    margin-left: auto !important;
}

@media print {

    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    .container {
        min-width: 992px !important;
    }

    .navbar {
        display: none;
    }
}

/*! CSS Used from: Embedded */
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

::selection {
    background-color: #FF4081;
    color: #fff;
}

/*! CSS Used from: Embedded */
ul {
    list-style-type: none;
}

/*! CSS Used from: Embedded */
*,
::after,
::before {
    box-sizing: border-box;
}

nav {
    display: block;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

a {
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

button {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button {
    overflow: visible;
}

button {
    text-transform: none;
}

[type=button],
button {
    -webkit-appearance: button;
}

[type=button]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width:576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px;
    }
}

@media (min-width:576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px;
    }
}

.collapse:not(.show) {
    display: none;
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
}

.nav-link:focus,
.nav-link:hover {
    text-decoration: none;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem;
}

.navbar .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;

}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width:991.98px) {
    .navbar-expand-lg>.container {
        padding-right: 0;
        padding-left: 0;
    }

    .collapse .lifloat:nth-child(1) {
        animation: menuItems 0.1s;
    }

    .collapse .lifloat:nth-child(2) {
        animation: menuItems 0.2s;
    }

    .collapse .lifloat:nth-child(3) {
        animation: menuItems 0.3s;
    }

    .collapse .lifloat:nth-child(4) {
        animation: menuItems 0.4s;
    }

    .collapse ul:nth-child(2) .lifloat:nth-child(1) {
        animation: menuItems 0.5s;
    }

    .collapse ul:nth-child(2) .lifloat:nth-child(2) {
        animation: menuItems 0.6s;
    }

    .collapse ul:nth-child(2) .lifloat:nth-child(3) {
        animation: menuItems 0.7s;
    }

    .collapse ul:nth-child(2) .lifloat:nth-child(4) {
        animation: menuItems 0.8s;
    }

    .collapse ul:nth-child(2) .lifloat:nth-child(5) {
        animation: menuItems 0.9s;
    }


}

@keyframes menuItems {
    0% {
        opacity: 0;
        padding-left: 5rem;

    }

    100% {
        opacity: 1;
        padding-left: 0;

    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
    }

    .navbar-expand-lg>.container {
        flex-wrap: nowrap;
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

.navbar-dark .navbar-brand {
    color: #fff;
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    /* color:rgba(255,255,255,.75); */
    -webkit-transition: all 2s ease-out;
    -moz-transition: all 2s ease-out;
    -o-transition: all 2s ease-out;
    transition: all 2s ease-out;
    color: #FF4081;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, .5);
    border-color: rgba(255, 255, 255, .1);
}

/* .navbar-dark .navbar-toggler-icon{background-image:url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e);} */
.ml-auto {
    margin-left: auto !important;
}

@media print {

    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    .container {
        min-width: 992px !important;
    }

    .navbar {
        display: none;
    }
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

::selection {
    background-color: #FF4081;
    color: #fff;
}

ul {
    list-style-type: none;
}

.navbar {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1rem;
    z-index: 2000;
}

.navbar>.container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
    text-decoration: none;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 2.5em;
    height: 2.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width: 991.98px) {
    .navbar-expand-lg>.container {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .navbar {
        background-color: transparent;
    }

    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-lg>.container {
        flex-wrap: nowrap;
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

#mainNav .navbar-brand {
    font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    color: #212529;
}

#mainNav .navbar-nav .nav-item .nav-link {
    color: rgba(255, 255, 255, 0.9);
    font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    font-size: 1.5rem;
    padding: 1.75rem 0;
}

.blackOnWhite {
    color: rgba(10, 3, 3, 0.8) !important;
}

#mainNav .navbar-nav .nav-item .nav-link:hover,
#mainNav .navbar-nav .nav-item .nav-link:active {
    color: white;
}

@media (min-width: 992px) {
    #mainNav {
        box-shadow: none;
    }

    #mainNav .navbar-brand {
        color: rgba(255, 255, 255, 0.7);
    }

    #mainNav .navbar-brand:hover {
        color: #fff;
    }

    #mainNav .navbar-nav .nav-item .nav-link {
        color: rgba(255, 255, 255, 0.7);
        padding: 0 1rem;
        font-size: 1.2rem;
    }

    #mainNav .navbar-nav .nav-item .nav-link:hover {
        color: #fff;
    }

    #mainNav .navbar-nav .nav-item:last-child .nav-link {
        padding-right: 4rem;
    }
}

.active {
    color: #FF4081 !important;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.nav-link {
    color: rgba(255, 255, 255, .5);

}

.nav-link:hover {
    /* color:rgba(255,255,255,.75); */
    -webkit-transition: all 2s ease-out;
    -moz-transition: all 2s ease-out;
    -o-transition: all 2s ease-out;
    transition: all 2s ease-out;
    color: #FF4081;
}