ul {
  list-style-type: none;
}

.skills {
  width: 80%;
  max-width: 960px;
  height: 780px;
  margin: auto;
  position: relative;
  font-size: 1.5rem;
}

.skills li {
  cursor: pointer;
}

.lines {
  height: 80%;
  position: relative;
}

.line {
  height: inherit;
  width: 2px;

  position: absolute;
  background: rgba(238, 238, 238, 0.6);
}

.line.l--0 {
  left: 0;
}

.line.l--25 {
  left: 25%;
}

.line.l--50 {
  left: 50%;
}

.line.l--75 {
  left: 75%;
}

.line.l--100 {
  left: calc(100% - 1px);
}

.line__label {
  display: block;
  width: 100px;
  text-align: center;
  position: absolute;
  bottom: -20px;
  right: -50px;
}

.line__label.title {
  text-transform: uppercase;
  font-weight: bold;
}

.charts {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.chart {
  margin: 30px 0 0;
}

.chart:first-child {
  margin: 0;
}

.chart__title {
  display: block;
  margin: 0 0 10px;
  font-weight: bold;
  opacity: 0;
  animation: 1s anim-lightspeed-in ease forwards;
}

.chart--prod .chart__title {
  animation-delay: 3.3s;
}

.chart--design .chart__title {
  animation-delay: 4.5s;
}

.chart--horiz {
  overflow: hidden;
}

.chart__bar {
  height: 30px;
  margin-bottom: 10px;
  /* background: linear-gradient(to left, #41413c, #4c5454); */
  background-image: linear-gradient(to right bottom,
      rgb(52, 58, 64),
      rgba(40, 180, 133, 0.85));
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  opacity: 0;
  animation: 1s anim-lightspeed-in ease forwards;
}

.chart--dev .chart__bar:nth-of-type(12) {
  animation-delay: 3s;
}

.chart--dev .chart__bar:nth-of-type(11) {
  animation-delay: 2.7s;
}

.chart--dev .chart__bar:nth-of-type(10) {
  animation-delay: 2.5s;
}

.chart--dev .chart__bar:nth-of-type(9) {
  animation-delay: 2.3s;
}

.chart--dev .chart__bar:nth-of-type(8) {
  animation-delay: 2.1s;
}

.chart--dev .chart__bar:nth-of-type(7) {
  animation-delay: 1.9s;
}

.chart--dev .chart__bar:nth-of-type(6) {
  animation-delay: 1.7s;
}

.chart--dev .chart__bar:nth-of-type(5) {
  animation-delay: 1.5s;
}

.chart--dev .chart__bar:nth-of-type(4) {
  animation-delay: 1.3s;
}

.chart--dev .chart__bar:nth-of-type(3) {
  animation-delay: 1.1s;
}

.chart--dev .chart__bar:nth-of-type(2) {
  animation-delay: 0.9s;
}

.chart--dev .chart__bar:nth-of-type(1) {
  animation-delay: 0.7s;
}

/* .chart--prod .chart__bar:nth-of-type(1) {
  animation-delay: 4s;
}
.chart--design .chart__bar:nth-of-type(3) {
  animation-delay: 5.6s;
}
.chart--design .chart__bar:nth-of-type(2) {
  animation-delay: 5.4s;
} */
/* .chart--design .chart__bar:nth-of-type(12) {
  animation-delay: 5.2s;
} */

.chart__label {
  padding-left: 10px;
  line-height: 30px;
  color: white;
}

@keyframes anim-lightspeed-in {
  0% {
    transform: translateX(-200%);
    opacity: 1;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Right part */
.section-about {
  background-color: #f7f7f7;
  padding: 1rem 0 1rem 0;
  height: 100vh;
}

@media only screen and (max-width: 56.25em) {
  .section-about {
    padding: 20rem 0;
  }
}

.u-center-text {
  text-align: center !important;
}

.about-u-margin-bottom-big {
  margin-bottom: 3rem !important;
}

@media only screen and (max-width: 56.25em) {
  .u-margin-bottom-big {
    margin-bottom: 5rem !important;
  }
}

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: -webkit-gradient(linear,
      left top,
      right top,
      from(#7ed56f),
      to(#28b485));
  background-image: linear-gradient(to left, #41413c, #4c5454);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 0.2rem;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

@media only screen and (max-width: 56.25em) {
  .heading-secondary {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .heading-secondary {
    font-size: 2.5rem;
  }
}

.heading-secondary:hover {
  /* -webkit-transform: skewY(2deg) skewX(15deg) scale(1.1);
  transform: skewY(2deg) skewX(15deg) scale(1.1); */
  text-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.2);
}

.composition {
  position: relative;
  margin-top: 20%;
}

@media only screen and (max-width: 56.25em) {
  .composition {
    margin-top: -40%;
  }
}

.composition__photo {
  width: 55%;
  -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  position: absolute;
  z-index: 10;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  outline-offset: 2rem;
}

@media only screen and (max-width: 56.25em) {
  .composition__photo {
    float: left;
    position: relative;
    width: 33.33333333%;
    -webkit-box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.2);
    box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.2);
  }
}

.composition__photo--p1 {
  left: 0;
  top: -2rem;
}

@media only screen and (max-width: 56.25em) {
  .composition__photo--p1 {
    top: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.composition__photo--p2 {
  right: 0;
  top: 2rem;
}

@media only screen and (max-width: 56.25em) {
  .composition__photo--p2 {
    top: -1rem;
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    z-index: 100;
  }
}

.composition__photo--p3 {
  left: 20%;
  top: 10rem;
}

@media only screen and (max-width: 56.25em) {
  .composition__photo--p3 {
    top: 1rem;
    left: 0;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.composition__photo:hover {
  outline: 1.5rem solid #55c57a;
  -webkit-transform: scale(1.05) translateY(-0.5rem);
  transform: scale(1.05) translateY(-0.5rem);
  -webkit-box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 20;
}

.composition:hover .composition__photo:not(:hover) {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

@media only screen and (max-width: 56.25em) {
  .skills-left-side {
    margin-bottom: -28rem !important;
  }
}