.section-projects {
  background-color: #f7f7f7;
  padding: 7rem 0 7rem 0;
  height: 100%;
}

@media only screen and (max-width: 56.25em) {
  .section-projects {
    height: auto;
    padding: 5rem 0 10rem 0;
  }
}

.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}

@media only screen and (max-width: 56.25em) {
  .u-margin-bottom-big {
    margin-bottom: 5rem !important;
  }
}

.row {
  max-width: 114rem;
  margin: 0 auto;
}

.row:not(:last-child) {
  margin-bottom: 8rem;
}

@media only screen and (max-width: 56.25em) {
  .row:not(:last-child) {
    margin-bottom: 6rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .row {
    max-width: 50rem;
    padding: 0 3rem;
  }
}

.row::after {
  content: "";
  display: table;
  clear: both;
}

.row [class^="col-"] {
  float: left;
}

.row [class^="col-"]:not(:last-child) {
  margin-right: 6rem;
}

@media only screen and (max-width: 56.25em) {
  .row [class^="col-"]:not(:last-child) {
    margin-right: 0;
    margin-bottom: 6rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .row [class^="col-"] {
    width: 100% !important;
  }
}

.row .col-1-of-2 {
  width: calc((100% - 6rem) / 2);
}

.row .col-1-of-3 {
  width: calc((100% - 2 * 6rem) / 3);
}

.row .col-2-of-3 {
  width: calc(2 * ((100% - 2 * 6rem) / 3) + 6rem);
}

.row .col-1-of-4 {
  width: calc((100% - 3 * 6rem) / 4);
}

.row .col-2-of-4 {
  width: calc(2 * ((100% - 3 * 6rem) / 4) + 6rem);
}

.row .col-3-of-4 {
  width: calc(3 * ((100% - 3 * 6rem) / 4) + 2 * 6rem);
}

.card {
  -webkit-perspective: 150rem;
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 52rem;
  background-color: transparent;
  border: 0px;
}

.card__side {
  height: 52rem;
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 3px;
  overflow: hidden;
  -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
}

.card__side--front {
  background-color: #fff;
}

.card__side--back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.card__side--back-1 {
  background-image: -webkit-gradient(linear,
      left top,
      right bottom,
      from(rgba(46, 49, 49, .8)),
      to(rgba(50, 69, 69, .8)));
  background-image: linear-gradient(to right bottom,
      rgba(46, 49, 49, .8),
      rgba(50, 69, 69, .8));
}

/*  */
.card__side--back-2 {
  background-image: -webkit-gradient(linear,
      left top,
      right bottom,
      from(rgba(46, 49, 49, .8)),
      to(rgba(50, 69, 69, .8)));
  background-image: linear-gradient(to right bottom, rgba(46, 49, 49, .8), rgba(50, 69, 69, .8));
}

.card__side--back-3 {
  background-image: -webkit-gradient(linear,
      left top,
      right bottom,
      from(rgba(46, 49, 49, .8)),
      to(rgba(50, 69, 69, .8)));
  background-image: linear-gradient(to right bottom,
      rgba(46, 49, 49, .8),
      rgba(50, 69, 69, .8));
}

.card:hover .card__side--front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);

}

.card:hover .card__side--back {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}

.card__picture {
  background-size: contain;
  background-repeat: round;
  height: 23rem;
  background-blend-mode: screen;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.card__picture--1 {
  background-image: url(../../images/cleanupmyhouse.webp),url(../../images/cleanupmyhouse.png);
}

.card__picture--2 {

  background-image: url(../../images/pizzatruck.webp),url(../../images/pizzatruck.png);;
}

.card__picture--3 {
  background-image: url(../../images/rescuemyappliances.webp),url(../../images/rescuemyappliances.png);;
}
.card__picture--4 {
  background-image: url(../../images/wordpressAppliance.png),url(../../images/wordpressAppliance.png);;
}
.card__picture--5 {
  background-image: url(../../images/eCommerce.png),url(../../images/eCommerce.png);;
}
.card__picture--6 {
  background-image: url(../../images/albumFinder.png);;
}
.card__picture--7 {
  background-image: url(../../images/angularCRM.png);;
}
.card__heading {
  font-size: 2.8rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: right;
  color: #fff;
  position: absolute;
  top: 12rem;
  right: 2rem;
  width: 75%;
  line-height: 4rem;
}

.card__heading-span {
  padding: 1rem 1.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.card__heading-span--1 {
  background-image: -webkit-gradient(linear,
      left top,
      right bottom,
      from(rgba(46, 49, 49, .8)),
      to(rgba(50, 69, 69, .8)));
  background-image: linear-gradient(to right bottom,
      rgba(46, 49, 49, .8),
      rgba(50, 69, 69, .8));
}

.card__heading-span--2 {
  background-image: -webkit-gradient(linear,
      left top,
      right bottom,
      from(rgba(46, 49, 49, .8)),
      to(rgba(50, 69, 69, .8)));
  background-image: linear-gradient(to right bottom,
      rgba(46, 49, 49, .8),
      rgba(50, 69, 69, .8));
}



.card__heading-span--3 {
  background-image: -webkit-gradient(linear,
      left top,
      right bottom,
      from(rgba(46, 49, 49, .8)),
      to(rgba(50, 69, 69, .8)));
  background-image: linear-gradient(to right bottom,
      rgba(46, 49, 49, .8),
      rgba(50, 69, 69, .8));
}

.card__details {
  padding: 3rem;
}

.card__details ul {
  list-style: none;
  width: 80%;
  margin: 0 auto;
}

.card__details ul li {
  text-align: center;
  font-size: 1.5rem;
  padding: 1rem;
}

.card__details ul li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.card__cta {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
}

.card__price-box {
  text-align: left;
  color: #fff;
  margin-bottom: 8rem;
}

.card__price-only {
  font-size: 1.4rem;
  text-transform: uppercase;
}

.card__price-value {
  font-size: 6rem;
  font-weight: 100;
}

@media only screen and (max-width: 56.25em),
only screen and (hover: none) {
  .card {
    height: auto;
    border-radius: 3px;
    background-color: #fff;
    -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  }

  .card__side {
    height: auto;
    position: relative;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .card__side--back {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    -webkit-clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
  }

  .card:hover .card__side--front {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }

  .card__details {
    padding: 1rem 3rem;
  }

  .card__cta {
    position: relative;
    top: 0%;
    left: 0;
    -webkit-transform: translate(0);
    transform: translate(0);
    width: 100%;
    padding: 7rem 4rem 4rem 4rem;
  }

  .card__price-box {
    margin-bottom: 3rem;
  }

  .card__price-value {
    font-size: 4rem;
  }
}

.btn--project {
  border: 1px solid white !important;
  padding: 0 1rem;
}

.btn,
.btn:link,
.btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  border-radius: 0.5rem;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  font-size: 1.6rem;
  border: none;
  cursor: pointer;
}

.btn:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  color: #5a6766;
}

.btn:hover::after {
  -webkit-transform: scaleX(1.4) scaleY(1.6);
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn:active,
.btn:focus {
  outline: none;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.btn--white {
  color: white;
  font-size: 2.5rem;
}

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.btnGroup{
  display: flex;
  justify-content: space-evenly;
}
.gitButton{
  text-transform: lowercase;
  color: white;
  border: 1px solid white !important;
  padding: 0 1rem;
  text-decoration: none;
  display: inline-block;
  border-radius: 0.5rem;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  font-size: 1.6rem;
  cursor: pointer;
  display: flex;
  align-items: center;

}
.gitButton:hover{
  text-decoration: none;
  color: white;
}
.gitButton svg{
  width: 2rem;
  height: 2rem;
  margin-right: 2px;
  vertical-align: middle;
}