.article-container {
  position: relative;
  padding: 0;
}
.article-header {
  top: -5.7rem;

  background-image: -webkit-gradient(
      linear,
      left top,
      right bottom,
      from(rgba(46, 49, 49, 1)),
      to(rgba(50, 69, 69, 0.8))
    ),
    url(../../../images/macbook.jpg);
  height: 80vh;
  background-image: linear-gradient(
      to right bottom,
      rgba(46, 49, 49, 0.8),
      rgba(50, 69, 69, 0.8)
    ),
    url(../../../images/macbook.jpg);
  background-size: cover;
  background-position: top;
  position: relative;
}
.header-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2em;
}
.header-title h1 {
  text-transform: uppercase;
  font-size: 4.5rem;
}
.header-title-first {
  display: block;
  letter-spacing: 8.5rem;
}
.header-title-second {
  display: block;
  letter-spacing: 1.5rem;
}
.parent {
  display: flex;
  /* align-items: center; */
}
.left {
  flex-grow: 1;
  text-align: left;
  flex-basis: 75%;
  line-height: 3.2rem;
  padding: 1.4rem 5rem 0 5rem;
  font-size: 2.15em;
}
.left h2{
  padding: 2rem 0;
}
.right {
  flex-basis: 25%;
  text-align: left;
  padding: 1.4rem 2rem 0 2rem;
  font-size: 2em;
}
.right a {
  display: block;
  color: #38403e;
}
@media(max-width:992px) {
      .parent{
        display: block;
      }
      .parent .right{
        margin-top: 4rem;
        text-align: center;
        background-color: #eeeeee;
      }
      .header-title-first{
        letter-spacing: 5.7rem;
      }
      .header-title-second{
        letter-spacing: 1.7rem;
      }
  }
.script-body {
    background: #2b2b2b;
}
  pre[class*=language-] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
    border-radius: .3em;
} 
  code[class*=language-], pre[class*=language-] {
    color: #f8f8f2;
    background: none;
    font-family: Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}