body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.header {
  height: 85vh;
  background-image: -webkit-gradient(linear,
      left top, right bottom,
      from(rgba(46, 49, 49, 0.8)),
      to(rgba(50, 69, 69, 0.8))),
    url(/static/media/hero.c97a7457.webp),url(/static/media/hero.0f4df6a3.jpg);
  background-image: linear-gradient(to right bottom,
      rgba(46, 49, 49, 0.8),
      rgba(50, 69, 69, 0.8)),
    url(/static/media/hero.c97a7457.webp),url(/static/media/hero.0f4df6a3.jpg);
  background-size: cover;
  background-position: top;
  position: relative;
  /* background-attachment: fixed; */
}

@supports ((-webkit-clip-path: polygon(0 0)) or (clip-path: polygon(0 0))) or (-webkit-clip-path: polygon(0 0)) {
  .header {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100vh, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100vh, 0 100%);
    height: 100vh;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
only screen and (min-width: 125em) {
  .header {
    background-image: -webkit-gradient(linear,
        left top, right bottom,
        from(rgba(46, 49, 49, 0.8)),
        to(rgba(50, 69, 69, 0.8))),
        url(/static/media/macbook.8f589339.webp),url(/static/media/macbook.b8e0436a.jpg);
    background-image: linear-gradient(to right bottom,
        rgba(46, 49, 49, 0.8),
        rgba(50, 69, 69, 0.8)),
        url(/static/media/macbook.8f589339.webp),url(/static/media/macbook.b8e0436a.jpg);
  }
  .hero__scroll{
    padding-bottom: 16rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .header {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
  }
  .hero__scroll{
    padding-bottom: 16rem;
  }
}

.header__text-box {
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

@-webkit-keyframes moveInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-10rem);
    transform: translateX(-10rem);
  }

  80% {
    -webkit-transform: translateX(1rem);
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-10rem);
    transform: translateX(-10rem);
  }

  80% {
    -webkit-transform: translateX(1rem);
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@-webkit-keyframes moveInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10rem);
    transform: translateX(10rem);
  }

  80% {
    -webkit-transform: translateX(-1rem);
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10rem);
    transform: translateX(10rem);
  }

  80% {
    -webkit-transform: translateX(-1rem);
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@-webkit-keyframes moveInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(3rem);
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(3rem);
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@-webkit-keyframes moveUp{
  0% {
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%);
    opacity: 0;
  }
  
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes moveUp{
  0% {
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%);
    opacity: 0;
  }
  
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
  .hero__scroll{
    padding-bottom: 16rem;
  }
}

@media only screen and (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
  .hero__scroll{
    padding-bottom: 16rem;
  }
}

@media only screen and (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
  .hero__scroll{
    padding-bottom: 16rem;
  }
}

body {
  box-sizing: border-box;
}

@media only screen and (max-width: 56.25em) {
  body {
    padding: 0;
  }
  .hero__scroll{
    padding-bottom: 16rem;
  }
}

::selection {
  background-color:  #FF4081;
  color: #fff;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: #777;
}

.heading-primary {
  color: #fff;
  text-transform: uppercase;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  margin-bottom: 6rem;
}

.heading-primary--main {
  display: block;
  font-size: 6rem;
  font-weight: 400;
  letter-spacing: 3.5rem;
  -webkit-animation-name: moveInLeft;
  animation-name: moveInLeft;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@media only screen and (max-width: 37.5em) {
  .heading-primary--main {
    letter-spacing: 1rem;
    font-family: 5rem;
  }
  .hero__scroll{
    padding-bottom: 16rem;
  }
}

.heading-primary--sub {
  display: block;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 2.8rem;
  -webkit-animation: moveInRight 1s ease-out;
  animation: moveInRight 1s ease-out;
}

.hero__scroll {
  color: #7a7a7a;
  position: absolute;
  bottom: 60px;
  width: 200px;
  margin: auto;
  display: block;
  cursor: pointer;
  padding-bottom: 40px;
  left: 0;
  right: 0;
  text-transform: uppercase;
  text-align: center;
  -webkit-animation-delay: 2.8s;
          animation-delay: 2.8s;
  -webkit-animation: moveUp 1.7s ease-out;
  animation: moveUp 1.7s ease-out;
}
.hero__scroll .chevron {
  margin-top: 20px;
  display: block;
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  color: #FF4081;
}
.chevron.bottom:before {
  top: 0;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.chevron::before {
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: '';
  display: inline-block;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  vertical-align: top;
  width: 20px;
  -webkit-animation-name: pulse;
          animation-name: pulse;
}
@-webkit-keyframes pulse{
0% {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

50% {
  -webkit-transform: translate(0, 10px);
  transform: translate(0, 10px);
}
100% {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
}
@keyframes pulse{
0% {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

50% {
  -webkit-transform: translate(0, 10px);
  transform: translate(0, 10px);
}
100% {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
}


@media only screen and (max-width: 37.5em) {
  .heading-primary--sub {
    letter-spacing: 1.3rem;
  }
  .hero__scroll{
    padding-bottom: 16rem;
  }
}

.arrow-down {
  font-size: 2.25rem !important;
}
.section-projects {
  background-color: #f7f7f7;
  padding: 7rem 0 7rem 0;
  height: 100%;
}

@media only screen and (max-width: 56.25em) {
  .section-projects {
    height: auto;
    padding: 5rem 0 10rem 0;
  }
}

.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}

@media only screen and (max-width: 56.25em) {
  .u-margin-bottom-big {
    margin-bottom: 5rem !important;
  }
}

.row {
  max-width: 114rem;
  margin: 0 auto;
}

.row:not(:last-child) {
  margin-bottom: 8rem;
}

@media only screen and (max-width: 56.25em) {
  .row:not(:last-child) {
    margin-bottom: 6rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .row {
    max-width: 50rem;
    padding: 0 3rem;
  }
}

.row::after {
  content: "";
  display: table;
  clear: both;
}

.row [class^="col-"] {
  float: left;
}

.row [class^="col-"]:not(:last-child) {
  margin-right: 6rem;
}

@media only screen and (max-width: 56.25em) {
  .row [class^="col-"]:not(:last-child) {
    margin-right: 0;
    margin-bottom: 6rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .row [class^="col-"] {
    width: 100% !important;
  }
}

.row .col-1-of-2 {
  width: calc((100% - 6rem) / 2);
}

.row .col-1-of-3 {
  width: calc((100% - 2 * 6rem) / 3);
}

.row .col-2-of-3 {
  width: calc(2 * ((100% - 2 * 6rem) / 3) + 6rem);
}

.row .col-1-of-4 {
  width: calc((100% - 3 * 6rem) / 4);
}

.row .col-2-of-4 {
  width: calc(2 * ((100% - 3 * 6rem) / 4) + 6rem);
}

.row .col-3-of-4 {
  width: calc(3 * ((100% - 3 * 6rem) / 4) + 2 * 6rem);
}

.card {
  -webkit-perspective: 150rem;
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 52rem;
  background-color: transparent;
  border: 0px;
}

.card__side {
  height: 52rem;
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
}

.card__side--front {
  background-color: #fff;
}

.card__side--back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.card__side--back-1 {
  background-image: -webkit-gradient(linear,
      left top, right bottom,
      from(rgba(46, 49, 49, .8)),
      to(rgba(50, 69, 69, .8)));
  background-image: linear-gradient(to right bottom,
      rgba(46, 49, 49, .8),
      rgba(50, 69, 69, .8));
}

/*  */
.card__side--back-2 {
  background-image: -webkit-gradient(linear, left top, right bottom, from(rgba(46, 49, 49, .8)), to(rgba(50, 69, 69, .8)));
  background-image: linear-gradient(to right bottom, rgba(46, 49, 49, .8), rgba(50, 69, 69, .8));
}

.card__side--back-3 {
  background-image: -webkit-gradient(linear,
      left top, right bottom,
      from(rgba(46, 49, 49, .8)),
      to(rgba(50, 69, 69, .8)));
  background-image: linear-gradient(to right bottom,
      rgba(46, 49, 49, .8),
      rgba(50, 69, 69, .8));
}

.card:hover .card__side--front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);

}

.card:hover .card__side--back {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}

.card__picture {
  background-size: contain;
  background-repeat: round;
  height: 23rem;
  background-blend-mode: screen;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.card__picture--1 {
  background-image: url(/static/media/cleanupmyhouse.ac044437.webp),url(/static/media/cleanupmyhouse.6a44c8c5.png);
}

.card__picture--2 {

  background-image: url(/static/media/pizzatruck.93c3b7b6.webp),url(/static/media/pizzatruck.7140d4a9.png);;
}

.card__picture--3 {
  background-image: url(/static/media/rescuemyappliances.c82424d4.webp),url(/static/media/rescuemyappliances.e5fad36e.png);;
}
.card__picture--4 {
  background-image: url(/static/media/wordpressAppliance.a9d7abf0.png),url(/static/media/wordpressAppliance.a9d7abf0.png);;
}
.card__picture--5 {
  background-image: url(/static/media/eCommerce.f105cbb8.png),url(/static/media/eCommerce.f105cbb8.png);;
}
.card__picture--6 {
  background-image: url(/static/media/albumFinder.f3651012.png);;
}
.card__picture--7 {
  background-image: url(/static/media/angularCRM.3febccb3.png);;
}
.card__heading {
  font-size: 2.8rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: right;
  color: #fff;
  position: absolute;
  top: 12rem;
  right: 2rem;
  width: 75%;
  line-height: 4rem;
}

.card__heading-span {
  padding: 1rem 1.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.card__heading-span--1 {
  background-image: -webkit-gradient(linear,
      left top, right bottom,
      from(rgba(46, 49, 49, .8)),
      to(rgba(50, 69, 69, .8)));
  background-image: linear-gradient(to right bottom,
      rgba(46, 49, 49, .8),
      rgba(50, 69, 69, .8));
}

.card__heading-span--2 {
  background-image: -webkit-gradient(linear,
      left top, right bottom,
      from(rgba(46, 49, 49, .8)),
      to(rgba(50, 69, 69, .8)));
  background-image: linear-gradient(to right bottom,
      rgba(46, 49, 49, .8),
      rgba(50, 69, 69, .8));
}



.card__heading-span--3 {
  background-image: -webkit-gradient(linear,
      left top, right bottom,
      from(rgba(46, 49, 49, .8)),
      to(rgba(50, 69, 69, .8)));
  background-image: linear-gradient(to right bottom,
      rgba(46, 49, 49, .8),
      rgba(50, 69, 69, .8));
}

.card__details {
  padding: 3rem;
}

.card__details ul {
  list-style: none;
  width: 80%;
  margin: 0 auto;
}

.card__details ul li {
  text-align: center;
  font-size: 1.5rem;
  padding: 1rem;
}

.card__details ul li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.card__cta {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
}

.card__price-box {
  text-align: left;
  color: #fff;
  margin-bottom: 8rem;
}

.card__price-only {
  font-size: 1.4rem;
  text-transform: uppercase;
}

.card__price-value {
  font-size: 6rem;
  font-weight: 100;
}

@media only screen and (max-width: 56.25em),
only screen and (hover: none) {
  .card {
    height: auto;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  }

  .card__side {
    height: auto;
    position: relative;
    box-shadow: none;
  }

  .card__side--back {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    -webkit-clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
  }

  .card:hover .card__side--front {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }

  .card__details {
    padding: 1rem 3rem;
  }

  .card__cta {
    position: relative;
    top: 0%;
    left: 0;
    -webkit-transform: translate(0);
    transform: translate(0);
    width: 100%;
    padding: 7rem 4rem 4rem 4rem;
  }

  .card__price-box {
    margin-bottom: 3rem;
  }

  .card__price-value {
    font-size: 4rem;
  }
}

.btn--project {
  border: 1px solid white !important;
  padding: 0 1rem;
}

.btn,
.btn:link,
.btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  border-radius: 0.5rem;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  font-size: 1.6rem;
  border: none;
  cursor: pointer;
}

.btn:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  color: #5a6766;
}

.btn:hover::after {
  -webkit-transform: scaleX(1.4) scaleY(1.6);
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn:active,
.btn:focus {
  outline: none;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.btn--white {
  color: white;
  font-size: 2.5rem;
}

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.btnGroup{
  display: flex;
  justify-content: space-evenly;
}
.gitButton{
  text-transform: lowercase;
  color: white;
  border: 1px solid white !important;
  padding: 0 1rem;
  text-decoration: none;
  display: inline-block;
  border-radius: 0.5rem;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  font-size: 1.6rem;
  cursor: pointer;
  display: flex;
  align-items: center;

}
.gitButton:hover{
  text-decoration: none;
  color: white;
}
.gitButton svg{
  width: 2rem;
  height: 2rem;
  margin-right: 2px;
  vertical-align: middle;
}
ul {
  list-style-type: none;
}

.skills {
  width: 80%;
  max-width: 960px;
  height: 780px;
  margin: auto;
  position: relative;
  font-size: 1.5rem;
}

.skills li {
  cursor: pointer;
}

.lines {
  height: 80%;
  position: relative;
}

.line {
  height: inherit;
  width: 2px;

  position: absolute;
  background: rgba(238, 238, 238, 0.6);
}

.line.l--0 {
  left: 0;
}

.line.l--25 {
  left: 25%;
}

.line.l--50 {
  left: 50%;
}

.line.l--75 {
  left: 75%;
}

.line.l--100 {
  left: calc(100% - 1px);
}

.line__label {
  display: block;
  width: 100px;
  text-align: center;
  position: absolute;
  bottom: -20px;
  right: -50px;
}

.line__label.title {
  text-transform: uppercase;
  font-weight: bold;
}

.charts {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.chart {
  margin: 30px 0 0;
}

.chart:first-child {
  margin: 0;
}

.chart__title {
  display: block;
  margin: 0 0 10px;
  font-weight: bold;
  opacity: 0;
  -webkit-animation: 1s anim-lightspeed-in ease forwards;
          animation: 1s anim-lightspeed-in ease forwards;
}

.chart--prod .chart__title {
  -webkit-animation-delay: 3.3s;
          animation-delay: 3.3s;
}

.chart--design .chart__title {
  -webkit-animation-delay: 4.5s;
          animation-delay: 4.5s;
}

.chart--horiz {
  overflow: hidden;
}

.chart__bar {
  height: 30px;
  margin-bottom: 10px;
  /* background: linear-gradient(to left, #41413c, #4c5454); */
  background-image: -webkit-gradient(linear,
      left top, right bottom,
      from(rgb(52, 58, 64)),
      to(rgba(40, 180, 133, 0.85)));
  background-image: linear-gradient(to right bottom,
      rgb(52, 58, 64),
      rgba(40, 180, 133, 0.85));
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  opacity: 0;
  -webkit-animation: 1s anim-lightspeed-in ease forwards;
          animation: 1s anim-lightspeed-in ease forwards;
}

.chart--dev .chart__bar:nth-of-type(12) {
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}

.chart--dev .chart__bar:nth-of-type(11) {
  -webkit-animation-delay: 2.7s;
          animation-delay: 2.7s;
}

.chart--dev .chart__bar:nth-of-type(10) {
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
}

.chart--dev .chart__bar:nth-of-type(9) {
  -webkit-animation-delay: 2.3s;
          animation-delay: 2.3s;
}

.chart--dev .chart__bar:nth-of-type(8) {
  -webkit-animation-delay: 2.1s;
          animation-delay: 2.1s;
}

.chart--dev .chart__bar:nth-of-type(7) {
  -webkit-animation-delay: 1.9s;
          animation-delay: 1.9s;
}

.chart--dev .chart__bar:nth-of-type(6) {
  -webkit-animation-delay: 1.7s;
          animation-delay: 1.7s;
}

.chart--dev .chart__bar:nth-of-type(5) {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

.chart--dev .chart__bar:nth-of-type(4) {
  -webkit-animation-delay: 1.3s;
          animation-delay: 1.3s;
}

.chart--dev .chart__bar:nth-of-type(3) {
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
}

.chart--dev .chart__bar:nth-of-type(2) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

.chart--dev .chart__bar:nth-of-type(1) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}

/* .chart--prod .chart__bar:nth-of-type(1) {
  animation-delay: 4s;
}
.chart--design .chart__bar:nth-of-type(3) {
  animation-delay: 5.6s;
}
.chart--design .chart__bar:nth-of-type(2) {
  animation-delay: 5.4s;
} */
/* .chart--design .chart__bar:nth-of-type(12) {
  animation-delay: 5.2s;
} */

.chart__label {
  padding-left: 10px;
  line-height: 30px;
  color: white;
}

@-webkit-keyframes anim-lightspeed-in {
  0% {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

@keyframes anim-lightspeed-in {
  0% {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

/* Right part */
.section-about {
  background-color: #f7f7f7;
  padding: 1rem 0 1rem 0;
  height: 100vh;
}

@media only screen and (max-width: 56.25em) {
  .section-about {
    padding: 20rem 0;
  }
}

.u-center-text {
  text-align: center !important;
}

.about-u-margin-bottom-big {
  margin-bottom: 3rem !important;
}

@media only screen and (max-width: 56.25em) {
  .u-margin-bottom-big {
    margin-bottom: 5rem !important;
  }
}

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: -webkit-gradient(linear, right top, left top, from(#41413c), to(#4c5454));
  background-image: linear-gradient(to left, #41413c, #4c5454);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 0.2rem;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

@media only screen and (max-width: 56.25em) {
  .heading-secondary {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .heading-secondary {
    font-size: 2.5rem;
  }
}

.heading-secondary:hover {
  /* -webkit-transform: skewY(2deg) skewX(15deg) scale(1.1);
  transform: skewY(2deg) skewX(15deg) scale(1.1); */
  text-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.2);
}

.composition {
  position: relative;
  margin-top: 20%;
}

@media only screen and (max-width: 56.25em) {
  .composition {
    margin-top: -40%;
  }
}

.composition__photo {
  width: 55%;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  position: absolute;
  z-index: 10;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  outline-offset: 2rem;
}

@media only screen and (max-width: 56.25em) {
  .composition__photo {
    float: left;
    position: relative;
    width: 33.33333333%;
    box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.2);
  }
}

.composition__photo--p1 {
  left: 0;
  top: -2rem;
}

@media only screen and (max-width: 56.25em) {
  .composition__photo--p1 {
    top: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.composition__photo--p2 {
  right: 0;
  top: 2rem;
}

@media only screen and (max-width: 56.25em) {
  .composition__photo--p2 {
    top: -1rem;
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    z-index: 100;
  }
}

.composition__photo--p3 {
  left: 20%;
  top: 10rem;
}

@media only screen and (max-width: 56.25em) {
  .composition__photo--p3 {
    top: 1rem;
    left: 0;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.composition__photo:hover {
  outline: 1.5rem solid #55c57a;
  -webkit-transform: scale(1.05) translateY(-0.5rem);
  transform: scale(1.05) translateY(-0.5rem);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 20;
}

.composition:hover .composition__photo:not(:hover) {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

@media only screen and (max-width: 56.25em) {
  .skills-left-side {
    margin-bottom: -28rem !important;
  }
}
.section-stories {
  position: relative;
  padding: 15rem 0;
  height: 100vh;
}

@media only screen and (max-width: 56.25em) {
  .section-stories {
    padding: 10rem 0;
  }
}
.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.55;
  overflow: hidden;
}

.bg-video__content {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.stories-heading {
  margin-top: -10rem;
}
.white {
  color: white;
}
.u-center-text {
  text-align: center !important;
}
.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}

@media only screen and (max-width: 56.25em) {
  .u-margin-bottom-big {
    margin-bottom: 5rem !important;
  }
}
.story {
  width: 75%;
  margin: 0 auto;
  box-shadow: 0 3rem 6rem rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 3px;
  padding: 6rem;
  padding-left: 9rem;
  font-size: 1.6rem;
  max-height: 40rem;
}

@media only screen and (max-width: 56.25em) {
  .story {
    width: 100%;
    padding: 4rem;
    padding-left: 7rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .story {
    -webkit-transform: skewX(0);
    transform: skewX(0);
  }
}

.story__shape {
  width: 15rem;
  height: 15rem;
  float: left;
  /* -webkit-transform: translateX(-3rem) skewX(12deg);
  transform: translateX(-3rem) skewX(12deg); */
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

@supports ((-webkit-clip-path: polygon(0 0)) or (clip-path: polygon(0 0))) or
  (-webkit-clip-path: polygon(0 0)) {
  .story__shape {
    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    shape-outside: circle(50% at 50% 50%);
    border-radius: none;
  }
}

@media only screen and (max-width: 37.5em) {
  .story__shape {
    -webkit-transform: translateX(-3rem) skewX(0);
    transform: translateX(-3rem) skewX(0);
  }
}

.story__img {
  height: 100%;
  -webkit-transform: translateX(-2.5rem) scale(1.4);
  transform: translateX(-2.5rem) scale(1.4);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

/* .story__text {
  -webkit-transform: skewX(12deg);
  transform: skewX(12deg);
} */

@media only screen and (max-width: 37.5em) {
  .story__text {
    -webkit-transform: skewX(0);
    transform: skewX(0);
  }
}

.story__caption {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, 20%);
  transform: translate(-50%, 20%);
  color: #fff;
  text-transform: uppercase;
  font-size: 1.7rem;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.story:hover .story__caption {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.story:hover .story__img {
  -webkit-transform: translateX(-2.5rem) scale(1);
  transform: translateX(-2.5rem) scale(1);
  -webkit-filter: blur(3px) brightness(80%);
  filter: blur(3px) brightness(80%);
}
.heading-tertiary {
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
}
.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}
.blog-link {
  font-size: 2rem;
  position: absolute;

  top: 97%;

  left: 50%;

  -webkit-transform: translate(-50%, -50%);

          transform: translate(-50%, -50%);

  text-align: center;
}
.adjustBlogImage{
  -webkit-transform: translateX(-2rem);
          transform: translateX(-2rem);
}
.article-container {
  position: relative;
  padding: 0;
}
.article-header {
  top: -5.7rem;

  background-image: -webkit-gradient(
      linear,
      left top,
      right bottom,
      from(rgba(46, 49, 49, 1)),
      to(rgba(50, 69, 69, 0.8))
    ),
    url(/static/media/macbook.b8e0436a.jpg);
  height: 80vh;
  background-image: -webkit-gradient(
      linear,
      left top, right bottom,
      from(rgba(46, 49, 49, 0.8)),
      to(rgba(50, 69, 69, 0.8))
    ),
    url(/static/media/macbook.b8e0436a.jpg);
  background-image: linear-gradient(
      to right bottom,
      rgba(46, 49, 49, 0.8),
      rgba(50, 69, 69, 0.8)
    ),
    url(/static/media/macbook.b8e0436a.jpg);
  background-size: cover;
  background-position: top;
  position: relative;
}
.header-title {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: white;
  font-size: 2em;
}
.header-title h1 {
  text-transform: uppercase;
  font-size: 4.5rem;
}
.header-title-first {
  display: block;
  letter-spacing: 8.5rem;
}
.header-title-second {
  display: block;
  letter-spacing: 1.5rem;
}
.parent {
  display: flex;
  /* align-items: center; */
}
.left {
  flex-grow: 1;
  text-align: left;
  flex-basis: 75%;
  line-height: 3.2rem;
  padding: 1.4rem 5rem 0 5rem;
  font-size: 2.15em;
}
.left h2{
  padding: 2rem 0;
}
.right {
  flex-basis: 25%;
  text-align: left;
  padding: 1.4rem 2rem 0 2rem;
  font-size: 2em;
}
.right a {
  display: block;
  color: #38403e;
}
@media(max-width:992px) {
      .parent{
        display: block;
      }
      .parent .right{
        margin-top: 4rem;
        text-align: center;
        background-color: #eeeeee;
      }
      .header-title-first{
        letter-spacing: 5.7rem;
      }
      .header-title-second{
        letter-spacing: 1.7rem;
      }
  }
.script-body {
    background: #2b2b2b;
}
  pre[class*=language-] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
    border-radius: .3em;
} 
  code[class*=language-], pre[class*=language-] {
    color: #f8f8f2;
    background: none;
    font-family: Consolas,Monaco,Andale Mono,Ubuntu Mono,monospace;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}
/*! CSS Used from: https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css */
*,
::after,
::before {
    box-sizing: border-box;
}

nav {
    display: block;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

a {
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

button {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button {
    overflow: visible;
}

button {
    text-transform: none;
}

[type=button],
button {
    -webkit-appearance: button;
}

[type=button]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width:576px) {
    .container {
        max-width: 540px;
    }

}

@media (min-width:768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px;
    }
}

@media (min-width:576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px;
    }
}

.collapse:not(.show) {
    display: none;
}

.nav-link {
    display: block;
    padding: 1.75rem 1rem;
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.9);
}

.nav-link:focus,
.nav-link:hover {
    text-decoration: none;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem;
}

.navbar .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    background-color:rgba(46,49,49,0.9);
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width:991.98px) {
    .navbar-expand-lg>.container {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
        border-bottom:none;
    }   

    .navbar-expand-lg>.container {
        flex-wrap: nowrap;
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        background-color: transparent;
    }

    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

.navbar-dark .navbar-brand {
    color: #fff;
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, .75);
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, .5);
    border-color: rgba(255, 255, 255, .1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.ml-auto {
    margin-left: auto !important;
}

@media print {

    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    .container {
        min-width: 992px !important;
    }

    .navbar {
        display: none;
    }
}

/*! CSS Used from: Embedded */
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

::selection {
    background-color: #FF4081;
    color: #fff;
}

/*! CSS Used from: Embedded */
ul {
    list-style-type: none;
}

/*! CSS Used from: Embedded */
*,
::after,
::before {
    box-sizing: border-box;
}

nav {
    display: block;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

a {
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

button {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button {
    overflow: visible;
}

button {
    text-transform: none;
}

[type=button],
button {
    -webkit-appearance: button;
}

[type=button]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width:576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px;
    }
}

@media (min-width:576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px;
    }
}

.collapse:not(.show) {
    display: none;
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
}

.nav-link:focus,
.nav-link:hover {
    text-decoration: none;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem;
}

.navbar .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;

}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width:991.98px) {
    .navbar-expand-lg>.container {
        padding-right: 0;
        padding-left: 0;
    }

    .collapse .lifloat:nth-child(1) {
        -webkit-animation: menuItems 0.1s;
                animation: menuItems 0.1s;
    }

    .collapse .lifloat:nth-child(2) {
        -webkit-animation: menuItems 0.2s;
                animation: menuItems 0.2s;
    }

    .collapse .lifloat:nth-child(3) {
        -webkit-animation: menuItems 0.3s;
                animation: menuItems 0.3s;
    }

    .collapse .lifloat:nth-child(4) {
        -webkit-animation: menuItems 0.4s;
                animation: menuItems 0.4s;
    }

    .collapse ul:nth-child(2) .lifloat:nth-child(1) {
        -webkit-animation: menuItems 0.5s;
                animation: menuItems 0.5s;
    }

    .collapse ul:nth-child(2) .lifloat:nth-child(2) {
        -webkit-animation: menuItems 0.6s;
                animation: menuItems 0.6s;
    }

    .collapse ul:nth-child(2) .lifloat:nth-child(3) {
        -webkit-animation: menuItems 0.7s;
                animation: menuItems 0.7s;
    }

    .collapse ul:nth-child(2) .lifloat:nth-child(4) {
        -webkit-animation: menuItems 0.8s;
                animation: menuItems 0.8s;
    }

    .collapse ul:nth-child(2) .lifloat:nth-child(5) {
        -webkit-animation: menuItems 0.9s;
                animation: menuItems 0.9s;
    }


}

@-webkit-keyframes menuItems {
    0% {
        opacity: 0;
        padding-left: 5rem;

    }

    100% {
        opacity: 1;
        padding-left: 0;

    }
}

@keyframes menuItems {
    0% {
        opacity: 0;
        padding-left: 5rem;

    }

    100% {
        opacity: 1;
        padding-left: 0;

    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
    }

    .navbar-expand-lg>.container {
        flex-wrap: nowrap;
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

.navbar-dark .navbar-brand {
    color: #fff;
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    /* color:rgba(255,255,255,.75); */
    -webkit-transition: all 2s ease-out;
    transition: all 2s ease-out;
    color: #FF4081;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, .5);
    border-color: rgba(255, 255, 255, .1);
}

/* .navbar-dark .navbar-toggler-icon{background-image:url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e);} */
.ml-auto {
    margin-left: auto !important;
}

@media print {

    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    .container {
        min-width: 992px !important;
    }

    .navbar {
        display: none;
    }
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

::selection {
    background-color: #FF4081;
    color: #fff;
}

ul {
    list-style-type: none;
}

.navbar {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1rem;
    z-index: 2000;
}

.navbar>.container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
    text-decoration: none;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 2.5em;
    height: 2.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width: 991.98px) {
    .navbar-expand-lg>.container {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .navbar {
        background-color: transparent;
    }

    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-lg>.container {
        flex-wrap: nowrap;
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

#mainNav .navbar-brand {
    font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    color: #212529;
}

#mainNav .navbar-nav .nav-item .nav-link {
    color: rgba(255, 255, 255, 0.9);
    font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    font-size: 1.5rem;
    padding: 1.75rem 0;
}

.blackOnWhite {
    color: rgba(10, 3, 3, 0.8) !important;
}

#mainNav .navbar-nav .nav-item .nav-link:hover,
#mainNav .navbar-nav .nav-item .nav-link:active {
    color: white;
}

@media (min-width: 992px) {
    #mainNav {
        box-shadow: none;
    }

    #mainNav .navbar-brand {
        color: rgba(255, 255, 255, 0.7);
    }

    #mainNav .navbar-brand:hover {
        color: #fff;
    }

    #mainNav .navbar-nav .nav-item .nav-link {
        color: rgba(255, 255, 255, 0.7);
        padding: 0 1rem;
        font-size: 1.2rem;
    }

    #mainNav .navbar-nav .nav-item .nav-link:hover {
        color: #fff;
    }

    #mainNav .navbar-nav .nav-item:last-child .nav-link {
        padding-right: 4rem;
    }
}

.active {
    color: #FF4081 !important;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.nav-link {
    color: rgba(255, 255, 255, .5);

}

.nav-link:hover {
    /* color:rgba(255,255,255,.75); */
    -webkit-transition: all 2s ease-out;
    transition: all 2s ease-out;
    color: #FF4081;
}


.certificates {
    padding: 8rem 0 1rem 0;
    display: block;
    justify-content: center;
    background: -webkit-gradient(linear, left top, right bottom, from(rgba(46, 49, 49, .8)), to(rgba(50, 69, 69, .8)));
    background: linear-gradient(to right bottom, rgba(46, 49, 49, .8), rgba(50, 69, 69, .8));
    background: -webkit-gradient(linear,left top, right bottom,from(rgba(46,49,49,.8)),to(rgba(50,69,69,.8))),url(/static/media/macbook.8f589339.webp),url(/static/media/macbook.b8e0436a.jpg);
    background: linear-gradient(to right bottom,rgba(46,49,49,.8),rgba(50,69,69,.8)),url(/static/media/macbook.8f589339.webp),url(/static/media/macbook.b8e0436a.jpg);
    /* url(/static/media/macbook.b8e0436a.jpg); */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}

.container_cert {
    position: relative;
    width: 1000px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: auto;
}

.container_cert .card_cert {
    position: relative;
    height: 250px;
    padding: 1rem;
    background: #fff;
    display: flex;
    width: 45%;
    margin: 30px 0;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, .15);
}
.container_cert .card_cert .card_cert_inner{
    border: 1px solid grey;
    width: 100%;
}
.container_cert .card_cert .imgBx {
    position: absolute;
    width: 100px;
    height: 100px;
    left: -50px;
    top: calc(50% - 50px);
    background: 333;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    border-radius: 50%;
    border: 1px solid rgba(46, 49, 49, .8);
    background: #ffffff;
    /* clip-path: polygon(100% 50%,84.70057014808336% 54.5684167277018%,91.0518476172854% 60.99980941685713%,82.33578363789503% 63.39392013277814%,93.30127018922194% 75%,77.76736691019323% 71.30665001530522%,80.05203820042827% 80.05203820042827%,71.30665001530522% 77.76736691019323%,75% 93.30127018922192%,63.39392013277814% 82.33578363789503%,60.99980941685714% 91.0518476172854%,54.56841672770181% 84.70057014808336%,50% 100%,45.4315832722982% 84.70057014808336%,39.000190583142874% 91.0518476172854%,36.606079867221865% 82.33578363789505%,25.00000000000001% 93.30127018922194%,28.693349984694787% 77.76736691019323%,19.947961799571733% 80.05203820042827%,22.23263308980677% 71.30665001530522%,6.698729810778076% 75.00000000000001%,17.664216362104966% 63.39392013277815%,8.948152382714603% 60.99980941685715%,15.299429851916635% 54.568416727701816%,0% 50.00000000000001%,15.299429851916635% 45.431583272298205%,8.94815238271459% 39.00019058314288%,17.66421636210496% 36.60607986722188%,6.698729810778062% 25.000000000000014%,22.23263308980676% 28.693349984694787%,19.947961799571715% 19.947961799571747%,28.69334998469477% 22.232633089806775%,24.99999999999998% 6.698729810778083%,36.60607986722184% 17.664216362104973%,39.00019058314284% 8.948152382714603%,45.43158327229816% 15.299429851916642%,49.99999999999999% 0%,54.568416727701795% 15.299429851916635%,60.99980941685711% 8.94815238271459%,63.39392013277812% 17.66421636210496%,74.99999999999997% 6.6987298107780475%,71.3066500153052% 22.23263308980675%,80.05203820042826% 19.947961799571722%,77.76736691019323% 28.69334998469477%,93.30127018922192% 24.99999999999998%,82.33578363789502% 36.60607986722184%,91.0518476172854% 39.00019058314283%,84.70057014808336% 45.43158327229816%) */
}

.container_cert .card_cert:hover .imgBx {
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    /* animation: clip 10s infinite; */
}

.container_cert .card_cert .imgBx:before {
    content: attr(data-text);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 4em;
    color: rgba(255, 255, 255, .05);
    font-weight: 700;
    /*  */
}

  
  @-webkit-keyframes clip {
    0% {
      -webkit-clip-path: polygon(100% 50%,84.70057014808336% 54.5684167277018%,91.0518476172854% 60.99980941685713%,82.33578363789503% 63.39392013277814%,93.30127018922194% 75%,77.76736691019323% 71.30665001530522%,80.05203820042827% 80.05203820042827%,71.30665001530522% 77.76736691019323%,75% 93.30127018922192%,63.39392013277814% 82.33578363789503%,60.99980941685714% 91.0518476172854%,54.56841672770181% 84.70057014808336%,50% 100%,45.4315832722982% 84.70057014808336%,39.000190583142874% 91.0518476172854%,36.606079867221865% 82.33578363789505%,25.00000000000001% 93.30127018922194%,28.693349984694787% 77.76736691019323%,19.947961799571733% 80.05203820042827%,22.23263308980677% 71.30665001530522%,6.698729810778076% 75.00000000000001%,17.664216362104966% 63.39392013277815%,8.948152382714603% 60.99980941685715%,15.299429851916635% 54.568416727701816%,0% 50.00000000000001%,15.299429851916635% 45.431583272298205%,8.94815238271459% 39.00019058314288%,17.66421636210496% 36.60607986722188%,6.698729810778062% 25.000000000000014%,22.23263308980676% 28.693349984694787%,19.947961799571715% 19.947961799571747%,28.69334998469477% 22.232633089806775%,24.99999999999998% 6.698729810778083%,36.60607986722184% 17.664216362104973%,39.00019058314284% 8.948152382714603%,45.43158327229816% 15.299429851916642%,49.99999999999999% 0%,54.568416727701795% 15.299429851916635%,60.99980941685711% 8.94815238271459%,63.39392013277812% 17.66421636210496%,74.99999999999997% 6.6987298107780475%,71.3066500153052% 22.23263308980675%,80.05203820042826% 19.947961799571722%,77.76736691019323% 28.69334998469477%,93.30127018922192% 24.99999999999998%,82.33578363789502% 36.60607986722184%,91.0518476172854% 39.00019058314283%,84.70057014808336% 45.43158327229816%);
              clip-path: polygon(100% 50%,84.70057014808336% 54.5684167277018%,91.0518476172854% 60.99980941685713%,82.33578363789503% 63.39392013277814%,93.30127018922194% 75%,77.76736691019323% 71.30665001530522%,80.05203820042827% 80.05203820042827%,71.30665001530522% 77.76736691019323%,75% 93.30127018922192%,63.39392013277814% 82.33578363789503%,60.99980941685714% 91.0518476172854%,54.56841672770181% 84.70057014808336%,50% 100%,45.4315832722982% 84.70057014808336%,39.000190583142874% 91.0518476172854%,36.606079867221865% 82.33578363789505%,25.00000000000001% 93.30127018922194%,28.693349984694787% 77.76736691019323%,19.947961799571733% 80.05203820042827%,22.23263308980677% 71.30665001530522%,6.698729810778076% 75.00000000000001%,17.664216362104966% 63.39392013277815%,8.948152382714603% 60.99980941685715%,15.299429851916635% 54.568416727701816%,0% 50.00000000000001%,15.299429851916635% 45.431583272298205%,8.94815238271459% 39.00019058314288%,17.66421636210496% 36.60607986722188%,6.698729810778062% 25.000000000000014%,22.23263308980676% 28.693349984694787%,19.947961799571715% 19.947961799571747%,28.69334998469477% 22.232633089806775%,24.99999999999998% 6.698729810778083%,36.60607986722184% 17.664216362104973%,39.00019058314284% 8.948152382714603%,45.43158327229816% 15.299429851916642%,49.99999999999999% 0%,54.568416727701795% 15.299429851916635%,60.99980941685711% 8.94815238271459%,63.39392013277812% 17.66421636210496%,74.99999999999997% 6.6987298107780475%,71.3066500153052% 22.23263308980675%,80.05203820042826% 19.947961799571722%,77.76736691019323% 28.69334998469477%,93.30127018922192% 24.99999999999998%,82.33578363789502% 36.60607986722184%,91.0518476172854% 39.00019058314283%,84.70057014808336% 45.43158327229816%);
    }
    50% {
      -webkit-clip-path: polygon(84.70057014808336% 54.5684167277018%,91.0518476172854% 60.99980941685713%,82.33578363789503% 63.39392013277814%,93.30127018922194% 75%,77.76736691019323% 71.30665001530522%,80.05203820042827% 80.05203820042827%,71.30665001530522% 77.76736691019323%,75% 93.30127018922192%,63.39392013277814% 82.33578363789503%,60.99980941685714% 91.0518476172854%,54.56841672770181% 84.70057014808336%,50% 100%,45.4315832722982% 84.70057014808336%,39.000190583142874% 91.0518476172854%,36.606079867221865% 82.33578363789505%,25.00000000000001% 93.30127018922194%,28.693349984694787% 77.76736691019323%,19.947961799571733% 80.05203820042827%,22.23263308980677% 71.30665001530522%,6.698729810778076% 75.00000000000001%,17.664216362104966% 63.39392013277815%,8.948152382714603% 60.99980941685715%,15.299429851916635% 54.568416727701816%,0% 50.00000000000001%,15.299429851916635% 45.431583272298205%,8.94815238271459% 39.00019058314288%,17.66421636210496% 36.60607986722188%,6.698729810778062% 25.000000000000014%,22.23263308980676% 28.693349984694787%,19.947961799571715% 19.947961799571747%,28.69334998469477% 22.232633089806775%,24.99999999999998% 6.698729810778083%,36.60607986722184% 17.664216362104973%,39.00019058314284% 8.948152382714603%,45.43158327229816% 15.299429851916642%,49.99999999999999% 0%,54.568416727701795% 15.299429851916635%,60.99980941685711% 8.94815238271459%,63.39392013277812% 17.66421636210496%,74.99999999999997% 6.6987298107780475%,71.3066500153052% 22.23263308980675%,80.05203820042826% 19.947961799571722%,77.76736691019323% 28.69334998469477%,93.30127018922192% 24.99999999999998%,82.33578363789502% 36.60607986722184%,91.0518476172854% 39.00019058314283%,84.70057014808336% 45.43158327229816%,100% 50%);
              clip-path: polygon(84.70057014808336% 54.5684167277018%,91.0518476172854% 60.99980941685713%,82.33578363789503% 63.39392013277814%,93.30127018922194% 75%,77.76736691019323% 71.30665001530522%,80.05203820042827% 80.05203820042827%,71.30665001530522% 77.76736691019323%,75% 93.30127018922192%,63.39392013277814% 82.33578363789503%,60.99980941685714% 91.0518476172854%,54.56841672770181% 84.70057014808336%,50% 100%,45.4315832722982% 84.70057014808336%,39.000190583142874% 91.0518476172854%,36.606079867221865% 82.33578363789505%,25.00000000000001% 93.30127018922194%,28.693349984694787% 77.76736691019323%,19.947961799571733% 80.05203820042827%,22.23263308980677% 71.30665001530522%,6.698729810778076% 75.00000000000001%,17.664216362104966% 63.39392013277815%,8.948152382714603% 60.99980941685715%,15.299429851916635% 54.568416727701816%,0% 50.00000000000001%,15.299429851916635% 45.431583272298205%,8.94815238271459% 39.00019058314288%,17.66421636210496% 36.60607986722188%,6.698729810778062% 25.000000000000014%,22.23263308980676% 28.693349984694787%,19.947961799571715% 19.947961799571747%,28.69334998469477% 22.232633089806775%,24.99999999999998% 6.698729810778083%,36.60607986722184% 17.664216362104973%,39.00019058314284% 8.948152382714603%,45.43158327229816% 15.299429851916642%,49.99999999999999% 0%,54.568416727701795% 15.299429851916635%,60.99980941685711% 8.94815238271459%,63.39392013277812% 17.66421636210496%,74.99999999999997% 6.6987298107780475%,71.3066500153052% 22.23263308980675%,80.05203820042826% 19.947961799571722%,77.76736691019323% 28.69334998469477%,93.30127018922192% 24.99999999999998%,82.33578363789502% 36.60607986722184%,91.0518476172854% 39.00019058314283%,84.70057014808336% 45.43158327229816%,100% 50%);
    }
     100% {
      -webkit-clip-path: polygon(91.0518476172854% 60.99980941685713%,82.33578363789503% 63.39392013277814%,93.30127018922194% 75%,77.76736691019323% 71.30665001530522%,80.05203820042827% 80.05203820042827%,71.30665001530522% 77.76736691019323%,75% 93.30127018922192%,63.39392013277814% 82.33578363789503%,60.99980941685714% 91.0518476172854%,54.56841672770181% 84.70057014808336%,50% 100%,45.4315832722982% 84.70057014808336%,39.000190583142874% 91.0518476172854%,36.606079867221865% 82.33578363789505%,25.00000000000001% 93.30127018922194%,28.693349984694787% 77.76736691019323%,19.947961799571733% 80.05203820042827%,22.23263308980677% 71.30665001530522%,6.698729810778076% 75.00000000000001%,17.664216362104966% 63.39392013277815%,8.948152382714603% 60.99980941685715%,15.299429851916635% 54.568416727701816%,0% 50.00000000000001%,15.299429851916635% 45.431583272298205%,8.94815238271459% 39.00019058314288%,17.66421636210496% 36.60607986722188%,6.698729810778062% 25.000000000000014%,22.23263308980676% 28.693349984694787%,19.947961799571715% 19.947961799571747%,28.69334998469477% 22.232633089806775%,24.99999999999998% 6.698729810778083%,36.60607986722184% 17.664216362104973%,39.00019058314284% 8.948152382714603%,45.43158327229816% 15.299429851916642%,49.99999999999999% 0%,54.568416727701795% 15.299429851916635%,60.99980941685711% 8.94815238271459%,63.39392013277812% 17.66421636210496%,74.99999999999997% 6.6987298107780475%,71.3066500153052% 22.23263308980675%,80.05203820042826% 19.947961799571722%,77.76736691019323% 28.69334998469477%,93.30127018922192% 24.99999999999998%,82.33578363789502% 36.60607986722184%,91.0518476172854% 39.00019058314283%,84.70057014808336% 45.43158327229816%,100% 50%,84.70057014808336% 54.5684167277018%);
              clip-path: polygon(91.0518476172854% 60.99980941685713%,82.33578363789503% 63.39392013277814%,93.30127018922194% 75%,77.76736691019323% 71.30665001530522%,80.05203820042827% 80.05203820042827%,71.30665001530522% 77.76736691019323%,75% 93.30127018922192%,63.39392013277814% 82.33578363789503%,60.99980941685714% 91.0518476172854%,54.56841672770181% 84.70057014808336%,50% 100%,45.4315832722982% 84.70057014808336%,39.000190583142874% 91.0518476172854%,36.606079867221865% 82.33578363789505%,25.00000000000001% 93.30127018922194%,28.693349984694787% 77.76736691019323%,19.947961799571733% 80.05203820042827%,22.23263308980677% 71.30665001530522%,6.698729810778076% 75.00000000000001%,17.664216362104966% 63.39392013277815%,8.948152382714603% 60.99980941685715%,15.299429851916635% 54.568416727701816%,0% 50.00000000000001%,15.299429851916635% 45.431583272298205%,8.94815238271459% 39.00019058314288%,17.66421636210496% 36.60607986722188%,6.698729810778062% 25.000000000000014%,22.23263308980676% 28.693349984694787%,19.947961799571715% 19.947961799571747%,28.69334998469477% 22.232633089806775%,24.99999999999998% 6.698729810778083%,36.60607986722184% 17.664216362104973%,39.00019058314284% 8.948152382714603%,45.43158327229816% 15.299429851916642%,49.99999999999999% 0%,54.568416727701795% 15.299429851916635%,60.99980941685711% 8.94815238271459%,63.39392013277812% 17.66421636210496%,74.99999999999997% 6.6987298107780475%,71.3066500153052% 22.23263308980675%,80.05203820042826% 19.947961799571722%,77.76736691019323% 28.69334998469477%,93.30127018922192% 24.99999999999998%,82.33578363789502% 36.60607986722184%,91.0518476172854% 39.00019058314283%,84.70057014808336% 45.43158327229816%,100% 50%,84.70057014808336% 54.5684167277018%);
    }
  }

  
  @keyframes clip {
    0% {
      -webkit-clip-path: polygon(100% 50%,84.70057014808336% 54.5684167277018%,91.0518476172854% 60.99980941685713%,82.33578363789503% 63.39392013277814%,93.30127018922194% 75%,77.76736691019323% 71.30665001530522%,80.05203820042827% 80.05203820042827%,71.30665001530522% 77.76736691019323%,75% 93.30127018922192%,63.39392013277814% 82.33578363789503%,60.99980941685714% 91.0518476172854%,54.56841672770181% 84.70057014808336%,50% 100%,45.4315832722982% 84.70057014808336%,39.000190583142874% 91.0518476172854%,36.606079867221865% 82.33578363789505%,25.00000000000001% 93.30127018922194%,28.693349984694787% 77.76736691019323%,19.947961799571733% 80.05203820042827%,22.23263308980677% 71.30665001530522%,6.698729810778076% 75.00000000000001%,17.664216362104966% 63.39392013277815%,8.948152382714603% 60.99980941685715%,15.299429851916635% 54.568416727701816%,0% 50.00000000000001%,15.299429851916635% 45.431583272298205%,8.94815238271459% 39.00019058314288%,17.66421636210496% 36.60607986722188%,6.698729810778062% 25.000000000000014%,22.23263308980676% 28.693349984694787%,19.947961799571715% 19.947961799571747%,28.69334998469477% 22.232633089806775%,24.99999999999998% 6.698729810778083%,36.60607986722184% 17.664216362104973%,39.00019058314284% 8.948152382714603%,45.43158327229816% 15.299429851916642%,49.99999999999999% 0%,54.568416727701795% 15.299429851916635%,60.99980941685711% 8.94815238271459%,63.39392013277812% 17.66421636210496%,74.99999999999997% 6.6987298107780475%,71.3066500153052% 22.23263308980675%,80.05203820042826% 19.947961799571722%,77.76736691019323% 28.69334998469477%,93.30127018922192% 24.99999999999998%,82.33578363789502% 36.60607986722184%,91.0518476172854% 39.00019058314283%,84.70057014808336% 45.43158327229816%);
              clip-path: polygon(100% 50%,84.70057014808336% 54.5684167277018%,91.0518476172854% 60.99980941685713%,82.33578363789503% 63.39392013277814%,93.30127018922194% 75%,77.76736691019323% 71.30665001530522%,80.05203820042827% 80.05203820042827%,71.30665001530522% 77.76736691019323%,75% 93.30127018922192%,63.39392013277814% 82.33578363789503%,60.99980941685714% 91.0518476172854%,54.56841672770181% 84.70057014808336%,50% 100%,45.4315832722982% 84.70057014808336%,39.000190583142874% 91.0518476172854%,36.606079867221865% 82.33578363789505%,25.00000000000001% 93.30127018922194%,28.693349984694787% 77.76736691019323%,19.947961799571733% 80.05203820042827%,22.23263308980677% 71.30665001530522%,6.698729810778076% 75.00000000000001%,17.664216362104966% 63.39392013277815%,8.948152382714603% 60.99980941685715%,15.299429851916635% 54.568416727701816%,0% 50.00000000000001%,15.299429851916635% 45.431583272298205%,8.94815238271459% 39.00019058314288%,17.66421636210496% 36.60607986722188%,6.698729810778062% 25.000000000000014%,22.23263308980676% 28.693349984694787%,19.947961799571715% 19.947961799571747%,28.69334998469477% 22.232633089806775%,24.99999999999998% 6.698729810778083%,36.60607986722184% 17.664216362104973%,39.00019058314284% 8.948152382714603%,45.43158327229816% 15.299429851916642%,49.99999999999999% 0%,54.568416727701795% 15.299429851916635%,60.99980941685711% 8.94815238271459%,63.39392013277812% 17.66421636210496%,74.99999999999997% 6.6987298107780475%,71.3066500153052% 22.23263308980675%,80.05203820042826% 19.947961799571722%,77.76736691019323% 28.69334998469477%,93.30127018922192% 24.99999999999998%,82.33578363789502% 36.60607986722184%,91.0518476172854% 39.00019058314283%,84.70057014808336% 45.43158327229816%);
    }
    50% {
      -webkit-clip-path: polygon(84.70057014808336% 54.5684167277018%,91.0518476172854% 60.99980941685713%,82.33578363789503% 63.39392013277814%,93.30127018922194% 75%,77.76736691019323% 71.30665001530522%,80.05203820042827% 80.05203820042827%,71.30665001530522% 77.76736691019323%,75% 93.30127018922192%,63.39392013277814% 82.33578363789503%,60.99980941685714% 91.0518476172854%,54.56841672770181% 84.70057014808336%,50% 100%,45.4315832722982% 84.70057014808336%,39.000190583142874% 91.0518476172854%,36.606079867221865% 82.33578363789505%,25.00000000000001% 93.30127018922194%,28.693349984694787% 77.76736691019323%,19.947961799571733% 80.05203820042827%,22.23263308980677% 71.30665001530522%,6.698729810778076% 75.00000000000001%,17.664216362104966% 63.39392013277815%,8.948152382714603% 60.99980941685715%,15.299429851916635% 54.568416727701816%,0% 50.00000000000001%,15.299429851916635% 45.431583272298205%,8.94815238271459% 39.00019058314288%,17.66421636210496% 36.60607986722188%,6.698729810778062% 25.000000000000014%,22.23263308980676% 28.693349984694787%,19.947961799571715% 19.947961799571747%,28.69334998469477% 22.232633089806775%,24.99999999999998% 6.698729810778083%,36.60607986722184% 17.664216362104973%,39.00019058314284% 8.948152382714603%,45.43158327229816% 15.299429851916642%,49.99999999999999% 0%,54.568416727701795% 15.299429851916635%,60.99980941685711% 8.94815238271459%,63.39392013277812% 17.66421636210496%,74.99999999999997% 6.6987298107780475%,71.3066500153052% 22.23263308980675%,80.05203820042826% 19.947961799571722%,77.76736691019323% 28.69334998469477%,93.30127018922192% 24.99999999999998%,82.33578363789502% 36.60607986722184%,91.0518476172854% 39.00019058314283%,84.70057014808336% 45.43158327229816%,100% 50%);
              clip-path: polygon(84.70057014808336% 54.5684167277018%,91.0518476172854% 60.99980941685713%,82.33578363789503% 63.39392013277814%,93.30127018922194% 75%,77.76736691019323% 71.30665001530522%,80.05203820042827% 80.05203820042827%,71.30665001530522% 77.76736691019323%,75% 93.30127018922192%,63.39392013277814% 82.33578363789503%,60.99980941685714% 91.0518476172854%,54.56841672770181% 84.70057014808336%,50% 100%,45.4315832722982% 84.70057014808336%,39.000190583142874% 91.0518476172854%,36.606079867221865% 82.33578363789505%,25.00000000000001% 93.30127018922194%,28.693349984694787% 77.76736691019323%,19.947961799571733% 80.05203820042827%,22.23263308980677% 71.30665001530522%,6.698729810778076% 75.00000000000001%,17.664216362104966% 63.39392013277815%,8.948152382714603% 60.99980941685715%,15.299429851916635% 54.568416727701816%,0% 50.00000000000001%,15.299429851916635% 45.431583272298205%,8.94815238271459% 39.00019058314288%,17.66421636210496% 36.60607986722188%,6.698729810778062% 25.000000000000014%,22.23263308980676% 28.693349984694787%,19.947961799571715% 19.947961799571747%,28.69334998469477% 22.232633089806775%,24.99999999999998% 6.698729810778083%,36.60607986722184% 17.664216362104973%,39.00019058314284% 8.948152382714603%,45.43158327229816% 15.299429851916642%,49.99999999999999% 0%,54.568416727701795% 15.299429851916635%,60.99980941685711% 8.94815238271459%,63.39392013277812% 17.66421636210496%,74.99999999999997% 6.6987298107780475%,71.3066500153052% 22.23263308980675%,80.05203820042826% 19.947961799571722%,77.76736691019323% 28.69334998469477%,93.30127018922192% 24.99999999999998%,82.33578363789502% 36.60607986722184%,91.0518476172854% 39.00019058314283%,84.70057014808336% 45.43158327229816%,100% 50%);
    }
     100% {
      -webkit-clip-path: polygon(91.0518476172854% 60.99980941685713%,82.33578363789503% 63.39392013277814%,93.30127018922194% 75%,77.76736691019323% 71.30665001530522%,80.05203820042827% 80.05203820042827%,71.30665001530522% 77.76736691019323%,75% 93.30127018922192%,63.39392013277814% 82.33578363789503%,60.99980941685714% 91.0518476172854%,54.56841672770181% 84.70057014808336%,50% 100%,45.4315832722982% 84.70057014808336%,39.000190583142874% 91.0518476172854%,36.606079867221865% 82.33578363789505%,25.00000000000001% 93.30127018922194%,28.693349984694787% 77.76736691019323%,19.947961799571733% 80.05203820042827%,22.23263308980677% 71.30665001530522%,6.698729810778076% 75.00000000000001%,17.664216362104966% 63.39392013277815%,8.948152382714603% 60.99980941685715%,15.299429851916635% 54.568416727701816%,0% 50.00000000000001%,15.299429851916635% 45.431583272298205%,8.94815238271459% 39.00019058314288%,17.66421636210496% 36.60607986722188%,6.698729810778062% 25.000000000000014%,22.23263308980676% 28.693349984694787%,19.947961799571715% 19.947961799571747%,28.69334998469477% 22.232633089806775%,24.99999999999998% 6.698729810778083%,36.60607986722184% 17.664216362104973%,39.00019058314284% 8.948152382714603%,45.43158327229816% 15.299429851916642%,49.99999999999999% 0%,54.568416727701795% 15.299429851916635%,60.99980941685711% 8.94815238271459%,63.39392013277812% 17.66421636210496%,74.99999999999997% 6.6987298107780475%,71.3066500153052% 22.23263308980675%,80.05203820042826% 19.947961799571722%,77.76736691019323% 28.69334998469477%,93.30127018922192% 24.99999999999998%,82.33578363789502% 36.60607986722184%,91.0518476172854% 39.00019058314283%,84.70057014808336% 45.43158327229816%,100% 50%,84.70057014808336% 54.5684167277018%);
              clip-path: polygon(91.0518476172854% 60.99980941685713%,82.33578363789503% 63.39392013277814%,93.30127018922194% 75%,77.76736691019323% 71.30665001530522%,80.05203820042827% 80.05203820042827%,71.30665001530522% 77.76736691019323%,75% 93.30127018922192%,63.39392013277814% 82.33578363789503%,60.99980941685714% 91.0518476172854%,54.56841672770181% 84.70057014808336%,50% 100%,45.4315832722982% 84.70057014808336%,39.000190583142874% 91.0518476172854%,36.606079867221865% 82.33578363789505%,25.00000000000001% 93.30127018922194%,28.693349984694787% 77.76736691019323%,19.947961799571733% 80.05203820042827%,22.23263308980677% 71.30665001530522%,6.698729810778076% 75.00000000000001%,17.664216362104966% 63.39392013277815%,8.948152382714603% 60.99980941685715%,15.299429851916635% 54.568416727701816%,0% 50.00000000000001%,15.299429851916635% 45.431583272298205%,8.94815238271459% 39.00019058314288%,17.66421636210496% 36.60607986722188%,6.698729810778062% 25.000000000000014%,22.23263308980676% 28.693349984694787%,19.947961799571715% 19.947961799571747%,28.69334998469477% 22.232633089806775%,24.99999999999998% 6.698729810778083%,36.60607986722184% 17.664216362104973%,39.00019058314284% 8.948152382714603%,45.43158327229816% 15.299429851916642%,49.99999999999999% 0%,54.568416727701795% 15.299429851916635%,60.99980941685711% 8.94815238271459%,63.39392013277812% 17.66421636210496%,74.99999999999997% 6.6987298107780475%,71.3066500153052% 22.23263308980675%,80.05203820042826% 19.947961799571722%,77.76736691019323% 28.69334998469477%,93.30127018922192% 24.99999999999998%,82.33578363789502% 36.60607986722184%,91.0518476172854% 39.00019058314283%,84.70057014808336% 45.43158327229816%,100% 50%,84.70057014808336% 54.5684167277018%);
    }
  }
/*  */


.container_cert .card_cert .imgBx img {
    max-width: 60px;
    -webkit-transition: 05s ease-in-out;
    transition: 05s ease-in-out;
}

.container_cert .card_cert:hover .imgBx img {
    max-width: 75px;
}

.container_cert .card_cert .content {
    position: absolute;
    right: 0;
    width: calc(100% - 50px);
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container_cert .card_cert .content .content_inner{
position: relative;

}
.container_cert .card_cert .content h3 {
    margin-bottom: 5px;
    font-size: 1.8rem;
}

.container_cert .card_cert .content p {
    font-size: 1.5rem;
}

.container_cert .card_cert .content a {
    display: inline-block;
    margin-top: 10px;
    padding: 5px 10px;
    background: #ffffff;
    text-decoration: none;
    color: #333;
    cursor: default;
    position: absolute;
    bottom: -10px;
    right: 0;
}

@media(max-width:992px) {
    .container_cert {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .container_cert .card_cert {
        width: 400px;
    }
}

@media(max-width:768px) {
    .container_cert .card_cert {
        max-width: 300px;
        flex-direction: column;
        height: auto;
    }

    .container_cert .card_cert .imgBx {
        position: relative;
    }

    .container_cert .card_cert .imgBx,
    .container_cert .card_cert:hover .imgBx {
        width: 150px;
        height: 150px;
        left: 0;
    }

    .container_cert .card_cert .imgBx {
        max-width: 100%;
    }

    .container_cert .card_cert:hover .imgBx img {
        max-width: 60%;
    }

    .container_cert .card_cert .content {
        position: relative;
        width: 100%;
    }
}
@media(min-width:0px) and (max-width:768px){
    .container_cert .card_cert .imgBx {
        position: relative;
        top: 0;
        margin: 0 auto;
        border: 0;
    }
}

