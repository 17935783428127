.header {
  height: 85vh;
  background-image: -webkit-gradient(linear,
      left top,
      right bottom,
      from(rgba(46, 49, 49, 1)),
      to(rgba(50, 69, 69, 0.8))),
    url(../../images/hero.webp),url(../../images/hero.jpg);
  background-image: linear-gradient(to right bottom,
      rgba(46, 49, 49, 0.8),
      rgba(50, 69, 69, 0.8)),
    url(../../images/hero.webp),url(../../images/hero.jpg);
  background-size: cover;
  background-position: top;
  position: relative;
  /* background-attachment: fixed; */
}

@supports (-webkit-clip-path: polygon(0 0)) or (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
  .header {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100vh, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100vh, 0 100%);
    height: 100vh;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
only screen and (min-width: 125em) {
  .header {
    background-image: -webkit-gradient(linear,
        left top,
        right bottom,
        rgba(46, 49, 49, 0.8),
        rgba(50, 69, 69, 0.8)),
      url(../../images/macbook.webp),url(../../images/macbook.jpg);
    background-image: linear-gradient(to right bottom,
        rgba(46, 49, 49, 0.8),
        rgba(50, 69, 69, 0.8)),
        url(../../images/macbook.webp),url(../../images/macbook.jpg);
  }
  .hero__scroll{
    padding-bottom: 16rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .header {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
  }
  .hero__scroll{
    padding-bottom: 16rem;
  }
}

.header__text-box {
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

@-webkit-keyframes moveInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-10rem);
    transform: translateX(-10rem);
  }

  80% {
    -webkit-transform: translateX(1rem);
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-10rem);
    transform: translateX(-10rem);
  }

  80% {
    -webkit-transform: translateX(1rem);
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@-webkit-keyframes moveInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10rem);
    transform: translateX(10rem);
  }

  80% {
    -webkit-transform: translateX(-1rem);
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10rem);
    transform: translateX(10rem);
  }

  80% {
    -webkit-transform: translateX(-1rem);
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@-webkit-keyframes moveInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(3rem);
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(3rem);
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes moveUp{
  0% {
    -webkit-transform: translate(0, 50%);
    -ms-transform: translate(0, 50%);
    transform: translate(0, 50%);
    opacity: 0;
  }
  
  100% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
  .hero__scroll{
    padding-bottom: 16rem;
  }
}

@media only screen and (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
  .hero__scroll{
    padding-bottom: 16rem;
  }
}

@media only screen and (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
  .hero__scroll{
    padding-bottom: 16rem;
  }
}

body {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 56.25em) {
  body {
    padding: 0;
  }
  .hero__scroll{
    padding-bottom: 16rem;
  }
}

::-moz-selection {
  /* background-color: #55c57a; */
  background-color: #FF4081;
  color: #fff;
}

::selection {
  background-color:  #FF4081;
  color: #fff;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: #777;
}

.heading-primary {
  color: #fff;
  text-transform: uppercase;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  margin-bottom: 6rem;
}

.heading-primary--main {
  display: block;
  font-size: 6rem;
  font-weight: 400;
  letter-spacing: 3.5rem;
  -webkit-animation-name: moveInLeft;
  animation-name: moveInLeft;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@media only screen and (max-width: 37.5em) {
  .heading-primary--main {
    letter-spacing: 1rem;
    font-family: 5rem;
  }
  .hero__scroll{
    padding-bottom: 16rem;
  }
}

.heading-primary--sub {
  display: block;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 2.8rem;
  -webkit-animation: moveInRight 1s ease-out;
  animation: moveInRight 1s ease-out;
}

.hero__scroll {
  color: #7a7a7a;
  position: absolute;
  bottom: 60px;
  width: 200px;
  margin: auto;
  display: block;
  cursor: pointer;
  padding-bottom: 40px;
  left: 0;
  right: 0;
  text-transform: uppercase;
  text-align: center;
  animation-delay: 2.8s;
  -webkit-animation: moveUp 1.7s ease-out;
  animation: moveUp 1.7s ease-out;
}
.hero__scroll .chevron {
  margin-top: 20px;
  display: block;
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  color: #FF4081;
}
.chevron.bottom:before {
  top: 0;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}
.chevron::before {
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: '';
  display: inline-block;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  vertical-align: top;
  width: 20px;
  animation-name: pulse;
}
@keyframes pulse{
0% {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

50% {
  -webkit-transform: translate(0, 10px);
  transform: translate(0, 10px);
}
100% {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
}


@media only screen and (max-width: 37.5em) {
  .heading-primary--sub {
    letter-spacing: 1.3rem;
  }
  .hero__scroll{
    padding-bottom: 16rem;
  }
}

.arrow-down {
  font-size: 2.25rem !important;
}