.section-stories {
  position: relative;
  padding: 15rem 0;
  height: 100vh;
}

@media only screen and (max-width: 56.25em) {
  .section-stories {
    padding: 10rem 0;
  }
}
.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.55;
  overflow: hidden;
}

.bg-video__content {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.stories-heading {
  margin-top: -10rem;
}
.white {
  color: white;
}
.u-center-text {
  text-align: center !important;
}
.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}

@media only screen and (max-width: 56.25em) {
  .u-margin-bottom-big {
    margin-bottom: 5rem !important;
  }
}
.story {
  width: 75%;
  margin: 0 auto;
  -webkit-box-shadow: 0 3rem 6rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 3rem 6rem rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 3px;
  padding: 6rem;
  padding-left: 9rem;
  font-size: 1.6rem;
  max-height: 40rem;
}

@media only screen and (max-width: 56.25em) {
  .story {
    width: 100%;
    padding: 4rem;
    padding-left: 7rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .story {
    -webkit-transform: skewX(0);
    transform: skewX(0);
  }
}

.story__shape {
  width: 15rem;
  height: 15rem;
  float: left;
  /* -webkit-transform: translateX(-3rem) skewX(12deg);
  transform: translateX(-3rem) skewX(12deg); */
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

@supports (-webkit-clip-path: polygon(0 0)) or (clip-path: polygon(0 0)) or
  (-webkit-clip-path: polygon(0 0)) {
  .story__shape {
    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    -webkit-shape-outside: circle(50% at 50% 50%);
    shape-outside: circle(50% at 50% 50%);
    border-radius: none;
  }
}

@media only screen and (max-width: 37.5em) {
  .story__shape {
    -webkit-transform: translateX(-3rem) skewX(0);
    transform: translateX(-3rem) skewX(0);
  }
}

.story__img {
  height: 100%;
  -webkit-transform: translateX(-2.5rem) scale(1.4);
  transform: translateX(-2.5rem) scale(1.4);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

/* .story__text {
  -webkit-transform: skewX(12deg);
  transform: skewX(12deg);
} */

@media only screen and (max-width: 37.5em) {
  .story__text {
    -webkit-transform: skewX(0);
    transform: skewX(0);
  }
}

.story__caption {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, 20%);
  transform: translate(-50%, 20%);
  color: #fff;
  text-transform: uppercase;
  font-size: 1.7rem;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.story:hover .story__caption {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.story:hover .story__img {
  -webkit-transform: translateX(-2.5rem) scale(1);
  transform: translateX(-2.5rem) scale(1);
  -webkit-filter: blur(3px) brightness(80%);
  filter: blur(3px) brightness(80%);
}
.heading-tertiary {
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
}
.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}
.blog-link {
  font-size: 2rem;
  position: absolute;

  top: 97%;

  left: 50%;

  transform: translate(-50%, -50%);

  text-align: center;
}
.adjustBlogImage{
  transform: translateX(-2rem);
}